<template>
    <div>
      <Banner />
      <NavBar>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link to="/human-resource">人力资源</router-link>
        </el-breadcrumb-item>
      </NavBar>
      <div id="content">
        <ModuleTitle chinese="人力资源" english="HUMAN RESOURCES" />
        <WithoutContent />
      </div>
    </div>
  </template>
  
  <script>
  import Banner from '../components/common/Banner'
  import NavBar from '../components/common/NavBar'
  import ModuleTitle from '../components/common/ModuleTitle'
  import WithoutContent from '../components/common/WithoutContent'
  export default {
    name:"Humanresource",
    components:{
      Banner,
      NavBar,
      ModuleTitle,
      WithoutContent
    }
  }
  </script>
  
  <style scoped>
    #content{
      max-width: 1200px;
      margin: auto;
      display: block;
    }
  </style>